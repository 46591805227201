<template>
  <div class="geozone">
    <div class="my-container geozone__container">
      <Spinner :is-loading="!(isMapAndTransportLoaded && isTemperatureLoaded)" :opacity="0.3" />
      <div id="map" />
      <div class="gz-info">
        <div class="gz-info__head">
          <h4>Список ТС</h4>
        </div>
        <ul v-if="mapTransports && mapTransports.length > 0" class="gz-info__list scrolled">
          <li
            v-for="(transport, index) in overlapTransports"
            :key="transport.id"
            class="gz-info__list-item"
            :class="{
              'gz-info__list-item--active': selectedItemIndex === index,
            }"
            @click="selectedItemIndex = index"
          >
            <div class="gz-info__item-main">
              <span>
                <span class="gz-info__item-body">{{
                  translateBody(transport.frontTransport.body)
                }}</span>
                {{
                  (transport.frontTransport &&
                    transport.frontTransport.model &&
                    transport.frontTransport.model.name) ||
                  "-"
                }}</span
              >
              <span>{{
                (transport.frontTransport && transport.frontTransport.number) || "-"
              }}</span>
            </div>
            <div class="gz-info__item-extra">
              <span>
                {{
                  transport.frontTransport && transport.frontTransport.status
                    ? handleTransportStatus(transport.frontTransport.status)
                    : "-"
                }}
              </span>
              <span>
                Датчик:
                {{ transport.label || transport.id || "-" }}
              </span>
            </div>
          </li>
          <template v-if="isDataLoaded">
            <li
              v-for="(transport, index) in transportList"
              v-show="!busyTransportIndexes.includes(index)"
              :key="transport.id"
              :id="index"
              class="gz-info__list-item gz-info__list-item--disabled"
            >
              <div class="gz-info__item-main">
                <span>
                  <span class="gz-info__item-body">{{ translateBody(transport.body) }}</span>
                  {{ (transport.model && transport.model.name) || "-" }}</span
                >
                <span>{{ transport.number || "-" }}</span>
              </div>
              <div class="gz-info__item-extra">
                <span>
                  {{ transport.status ? handleTransportStatus(transport.status) : "-" }}
                </span>
                <span> Датчик: {{ transport.label || transport.tracker_id || "-" }} </span>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { loadYmap } from "vue-yandex-maps";
import { mapState } from "vuex";
import Spinner from "@/components/spinner";
import sleep from "@/utils/sleep";
import transportBodyMixin from "../../mixins/transportBody";

export default {
  name: "GeoZone",
  components: { Spinner },
  mixins: [transportBodyMixin],
  data() {
    return {
      isDataLoaded: false,
      isTransportLoaded: false,
      isTemperatureLoaded: false,
      ymaps: null,
      map: null,
      bufferMapTransports: [], // a copy of mapTransports with local write access
      mapTrackerIDs: [], // number[] of mapTransports IDs (related by index)
      transportCollection: null, // ymaps collection
      selectedItemIndex: null, // number
      balloonTemperatures: [], // number[] | null[] of temperatures related to overlapTransports by index
      busyTransportIndexes: [], // (new) number[] of indexes of hidden transportList items
      overlapTransports: [], // object[] pseudo computed array of overlapping objects between mapTransports and transportList
      cityZonePart: [], // Cities zone part
      cityZonePartCollection: null, // Cities zone part ymap
      coordinates: []
    };
  },
  computed: {
    ...mapState(["mapTransports", "transportList", "CityZonePart"]),
    isMapAndTransportLoaded() {
      return this.isDataLoaded && this.isTransportLoaded;
    },
  },
  watch: {
    isMapAndTransportLoaded() {
      if (this.isMapAndTransportLoaded === true) {
        let itemsProcessed = 0;
        const handleLoad = () => {
          this.isTemperatureLoaded = true;
        };

        this.overlapTransports.forEach((el, index, array) => {
          if ("wiatlon_id" in el.frontTransport && el.frontTransport.wiatlon_id) {
            this.$api
              .get(`/temperatura?id=${el.frontTransport.wiatlon_id}`)
              .then((response) => {
                this.balloonTemperatures.splice(index, 0, response.data);
                itemsProcessed++;
                if (itemsProcessed === array.length) {
                  handleLoad();
                }

              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            if (el.frontTransport.wiatlon_id === 0) {
              this.balloonTemperatures.push(0);
            } else {
              this.balloonTemperatures.push(null);
            }
            itemsProcessed++;
            if (itemsProcessed === array.length) {
              handleLoad();
            }
          }
        });
      }
    },
    selectedItemIndex() {
      this.transportCollection.get(this.selectedItemIndex).balloon.open();
    },
  },
  async created() {
    if (!this.mapTransports)
      await this.$store.dispatch("getMapTransports").then(() => {
        this.bufferMapTransports = this.mapTransports;
        this.bufferMapTransports.forEach((el) => {
          this.mapTrackerIDs.push(el.id);
        });
        this.isDataLoaded = true;
      });
    else {
      this.bufferMapTransports = this.mapTransports;
      this.bufferMapTransports.forEach((el) => {
        this.mapTrackerIDs.push(el.id);
      });
      this.isDataLoaded = true;
    }

    if (!this.CityZonePart.length)
      await this.$store.dispatch("getCityZoneParts").then(() => {
        this.cityZonePart = this.CityZonePart;
        this.isDataLoaded = true;
      });
    else 
      this.cityZonePart = this.CityZonePart;

    if (!this.transportList.length)
      await this.$store.dispatch("getTransportList", {type: 'get_without_trailers'}).then(async () => {
        while (!this.isDataLoaded) {
          await sleep(400);
        }
        if (this.isDataLoaded) {
          this.bufferMap();
        }
      });
    else
      this.bufferMap();

    if (this.$route.query.id && this.isMapAndTransportLoaded && this.isTemperatureLoaded) {
      var self = this;
      setTimeout(() => {
        this.mapTransports.forEach(function(elem, index) {
        if(elem.id === parseInt(self.$route.query.id)){
          self.selectedItemIndex = index;
        }
      });
      }, 500);
    }
  },
  async mounted() {
    const settings = {
      apiKey: process.env.VUE_APP_YMAPS_APIKEY,
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    };
    await loadYmap({ ...settings, debug: true });

    this.ymaps = window.ymaps;

    this.map = new this.ymaps.Map("map", {
      center: [43.27048, 76.91777],
      zoom: 9,
    });


    this.transportCollection = new this.ymaps.GeoObjectCollection(null, {
      preset: "island#yellowIcon",
    });

    this.cityZonePartCollection = new this.ymaps.GeoObjectCollection(null, {

    });

    (async () => {
      while (!(this.isDataLoaded && this.isTransportLoaded && this.isTemperatureLoaded)) {
        await sleep(400);
      }
      if (this.isDataLoaded && this.isTransportLoaded && this.isTemperatureLoaded) {
        this.addTransportToMap();
        this.addCityZonePart();
        this.centerAndZoomMap();
      }
    })();
  },
  beforeUnmount() {
    this.isDataLoaded = false;
    this.ymaps = null;
    this.map = null;
    this.bufferMapTransports = [];
    this.transportCollection = null;
    this.cityZonePartCollection = null;
    this.selectedItemIndex = null;
  },
  methods: {
    bufferMap() {
      this.bufferMapTransports.forEach((el) => {
        const objectIndex = this.transportList.findIndex((trEl, index) => {
          if (
            !this.busyTransportIndexes.includes(index) &&
            (trEl.tracker_id === el.id || trEl.label === el.label)
          ) {
            this.busyTransportIndexes.push(index);
            return true;
          } else return false;
        });
        let object = null;
        if (objectIndex === -1) {
          object = null;
        } else {
          object = this.transportList[objectIndex];
        }
        if (object && object.status) {
          this.overlapTransports.push({ ...el, frontTransport: object });
        }
      });
      this.isTransportLoaded = true;
    },
    handleTransportStatus(status) {
      switch (status) {
        case false:
        case null:
        case undefined:
        case "":
          return "-";
        case "free":
          return "Свободный";
        default:
          return (status[0].toUpperCase() + status.slice(1)).replace(/_/g, " ");
      }
    },
    centerAndZoomMap() {
      const centerAndZoom = this.ymaps.util.bounds.getCenterAndZoom(
        this.transportCollection.getBounds(),
        this.map.container.getSize(),
        this.map.options.get("projection")
      );
      if (this.transportCollection.getLength() <= 1) {
        this.map.setCenter(centerAndZoom.center, 17);
      } else {
        this.map.setCenter(centerAndZoom.center, centerAndZoom.zoom);
      }
    },
    addCityZonePart() {
      this.cityZonePart.forEach((el) => {
        this.coordinates = el.coordinates

        var myGeoObject = new this.ymaps.GeoObject({
          // Описываем геометрию геообъекта.
          geometry: {
            // Тип геометрии - "Многоугольник".
            type: "Polygon",
            // Указываем координаты вершин многоугольника.
            coordinates: [
              // Координаты вершин внешнего контура.
                this.coordinates
            ],
            // Задаем правило заливки внутренних контуров по алгоритму "nonZero".
            fillRule: "nonZero"
          },
          // Описываем свойства геообъекта.
          properties:{
            // Содержимое балуна.
            balloonContent: el.properties.description
          }
        }, {
          // Описываем опции геообъекта.
          // Цвет заливки.
          fillColor: '#00FF00',
          // Цвет обводки.
          strokeColor: '#0000FF',
          // Общая прозрачность (как для заливки, так и для обводки).
          opacity: 0.5,
          // Ширина обводки.
          strokeWidth: 5,
          // Стиль обводки.
          strokeStyle: 'shortdash'
        });

        this.cityZonePartCollection.add(myGeoObject)
      })
      // Добавляем многоугольник на карту.
      this.map.geoObjects.add(this.cityZonePartCollection);
    },
    addTransportToMap() {
      if (this.transportCollection && this.bufferMapTransports && this.overlapTransports) {
        this.overlapTransports.forEach((el) => {
          const placemark = new this.ymaps.Placemark(
            [el.coordination.lat, el.coordination.lng],
            {
              balloonContentHeader: `
              <div class="balloon-header">
                <div class="balloon-header__main">
                  <div class="balloon-header__title">${el.label || "-"} ${el.trailer?.label ? ' : ' + el.trailer?.label : '' || "-"}</div>
                  <div class="balloon-header__driver">
                    <span class="balloon-header__driver-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z"/></svg></span>
                    <span class="balloon-header__driver-name">-</span>
                    <span class="balloon-header__driver-phone">${el?.source?.phone || "-"}</span>
                  </div>
                </div>
                <div class="balloon-header__number">
                  ${(el.frontTransport && el.frontTransport.number) || "-"}
                </div>
              </div>`,

              balloonContentBody: `
              <div class="balloon-body">
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Скорость</div>
                    <div class="balloon-property-value">${
                      el.frontTransport.sensors && el.frontTransport.sensors.speed ? el.frontTransport.sensors.speed  : "-"
                    } км/ч</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 334.6 313.1 318.9 301.9 307.4L365.1 161.7C371.3 149.5 365.8 135.4 353.7 130C341.5 124.7 327.4 130.2 322 142.3L257.9 288C257.3 288 256.6 287.1 256 287.1C220.7 287.1 192 316.7 192 352C192 387.3 220.7 416 256 416V416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Топливо</div>
                    <div class="balloon-property-value">${
                      el.frontTransport.sensors && el.frontTransport.sensors.oil ? el.frontTransport.sensors.oil : "-"
                    } л</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.65 60.65 0 96 0H256C291.3 0 320 28.65 320 64V256H328C376.6 256 416 295.4 416 344V376C416 389.3 426.7 400 440 400C453.3 400 464 389.3 464 376V221.1C436.4 214.9 416 189.8 416 160V96L384 64C375.2 55.16 375.2 40.84 384 32C392.8 23.16 407.2 23.16 416 32L493.3 109.3C505.3 121.3 512 137.5 512 154.5V376C512 415.8 479.8 448 440 448C400.2 448 368 415.8 368 376V344C368 321.9 350.1 303.1 328 303.1H320V448C337.7 448 352 462.3 352 480C352 497.7 337.7 512 320 512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448V64zM96 176C96 184.8 103.2 192 112 192H240C248.8 192 256 184.8 256 176V80C256 71.16 248.8 64 240 64H112C103.2 64 96 71.16 96 80V176z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Температура</div>
                    <div class="balloon-property-value">${
                      el.frontTransport.sensors && el.frontTransport.sensors.temp? el.frontTransport.sensors.temp : "-"
                    } °C</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M176 322.9V160c0-8.75-7.25-16-16-16s-16 7.25-16 16v162.9c-18.62 6.625-32 24.25-32 45.13c0 26.5 21.5 48 48 48s48-21.5 48-48C208 347.1 194.6 329.5 176 322.9zM272 278.5V112c0-61.87-50.12-112-111.1-112S48 50.13 48 112v166.5c-19.75 24.75-32 55.5-32 89.5c0 79.5 64.5 143.1 144 143.1S304 447.5 304 368C304 334 291.8 303.1 272 278.5zM160 448c-44.13 0-80-35.87-80-79.1c0-25.5 12.25-48.88 32-63.75v-192.3c0-26.5 21.5-48 48-48s48 21.5 48 48v192.3c19.75 14.75 32 38.25 32 63.75C240 412.1 204.1 448 160 448z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Влажность</div>
                    <div class="balloon-property-value">${
    
                      el.frontTransport.sensors && el.frontTransport.sensors.humidity ? el.frontTransport.sensors.humidity : "-"
                    } %</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg viewBox="0 0 1050 1050" xmlns="http://www.w3.org/2000/svg"><path d="M543.792 22.208L512 0l-31.792 22.208C85.808 316.368 82.64 632.704 82.64 646.56 82.64 854.688 276.64 1024 512 1024s429.328-169.296 429.36-377.44c0-330.192-381.632-613.248-397.568-624.352zM512 940.8c-184.464 0-333.936-130.448-333.936-294.16 0-2.816 3.2-274.752 333.936-535.6 85.904 72.128 334 294.128 333.968 535.6C845.936 807.568 696.432 940.8 512 940.8z m-130.4-285.888c-22.256-94.4 60.4-194.288 60.4-194.288l-76.288-49.936c-6.4 5.6-108.176 130.48-79.536 260.88 15.92 69.408 66.768 127.696 149.488 169.296l50.864-72.208c-60.448-30.512-95.392-66.608-104.928-113.744z"/></svg>
                    </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Одометр</div>
                    <div class="balloon-property-value">${
                      el.frontTransport.sensors && el.frontTransport.sensors.odometr ? el.frontTransport.sensors.odometr : "-"
                    } км</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 334.6 313.1 318.9 301.9 307.4L365.1 161.7C371.3 149.5 365.8 135.4 353.7 130C341.5 124.7 327.4 130.2 322 142.3L257.9 288C257.3 288 256.6 287.1 256 287.1C220.7 287.1 192 316.7 192 352C192 387.3 220.7 416 256 416V416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z"/></svg>
                  </div>
                </div>
              </div>`,

              balloonContentFooter: `
                <div class="balloon-footer">
                  <ul>
                    <li>Местоположение: ${(el.coordination && el.coordination.lat) || "-"}, ${
                (el.coordination && el.coordination.lng) || "-"
              }</li>
                  </ul>
                </div>`,

              hintContent:
                el.label ||
                el.frontTransport.label ||
                el.frontTransport.number ||
                "Недостаточно Данных",
            },
            {
              preset: el.frontTransport.status
                ? el.coordination.speed
                  ? "islands#darkGreenDeliveryCircleIcon"
                  : "islands#grayDeliveryCircleIcon"
                : "islands#redDeliveryCircleIcon",
              hideIconOnBalloonOpen: false,
            }
          );
          this.transportCollection.add(placemark);
        });
        this.map.geoObjects.add(this.transportCollection);
      } else console.error("Error: couldn't add transport data to the map");
    },
  },
};
</script>

<style>
.balloon-header {
  display: flex;
  justify-content: space-between;
  min-width: max-content;
  width: 100%;
  font-weight: 500;
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
}
.balloon-header__number {
  border-radius: 12px;
  background-color: #e1e8fa;
  color: #5b6fa2;
  display: flex;
  align-items: center;
  padding: 0.35rem;
  margin-left: 1rem;
}
.balloon-header__main {
  flex-grow: 1;
}
.balloon-header__driver {
  opacity: 0.6;
  font-size: 0.85em;
  display: flex;
  align-content: center;
}
.balloon-header__driver-name {
  flex-grow: 5;
}
.balloon-header__driver-phone {
  flex-grow: 3;
}
.balloon-header__driver-icon {
  flex-basis: 1rem;
  margin-right: 0.3rem;
}
.balloon-header__driver-icon svg {
  max-height: 1rem;
}
.balloon-body {
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  min-width: max-content;
  width: 100%;
}
.balloon-property {
  padding: 0.5rem;
  display: flex;
  border-radius: 12px;
  background-color: #f3f2fa;
}
.balloon-property-main {
  flex-grow: 1;
  margin-right: 0.5rem;
}
.balloon-property-label {
  opacity: 0.6;
  font-size: 0.9em;
}
.balloon-property-value {
  font-size: 1.15em;
}
.balloon-property-icon {
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.balloon-property-icon svg {
  width: 1rem;
}
.balloon-footer {
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  width: 100%;
  min-width: max-content;
}
</style>

<style scoped>
.gz-info__item-body {
  opacity: 0.8;
}
.my-spinner {
  position: fixed;
  top: 7rem;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 100;
}
.-hidden {
  display: none;
}
.geozone {
  padding-top: 12px;
  padding-bottom: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.geozone__container {
  display: flex;
  flex-grow: 1;
}
#map {
  flex-grow: 1;
  margin-right: 12px;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
}
.gz-info {
  flex-basis: 25rem;
  border-radius: 12px;
  background-color: white;
  padding: 16px;
}
.scrolled {
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: content-box;
  padding-right: 8px;
}
.scrolled::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #7771;
}

.scrolled::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
  background-color: #7771;
}

.scrolled::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #7772;
}
.scrolled::-webkit-scrollbar-thumb:hover {
  background-color: #7773;
}
.scrolled::-webkit-scrollbar-thumb:active {
  background-color: #7774;
}
.gz-info__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #7772;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.gz-info__head h4 {
  margin: 0;
}
.gz-info__head-free {
  display: flex;
  align-items: center;
}
.gz-info__head-free-span {
  margin-right: 12px;
}
.gz-info__list-item {
  margin-bottom: 8px;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.15s ease;
  border: 2px solid #0000;
}
.gz-info__list-item:last-of-type {
  margin-bottom: 0;
}
.gz-info__list-item:hover {
  background-color: hsla(211, 100%, 50%, 0.1);
}
.gz-info__list-item:active {
  background-color: hsla(211, 100%, 50%, 0.2);
  border: 2px solid hsla(211, 100%, 50%, 0.3);
}
.gz-info__list-item--active {
  background-color: hsla(211, 100%, 50%, 0.2);
  border: 2px solid hsla(211, 100%, 50%, 0.3);
}
.gz-info__list-item--active:hover {
  background-color: hsla(211, 100%, 50%, 0.2);
  border: 2px solid hsla(211, 100%, 50%, 0.3);
}
.gz-info__list-item--disabled {
  cursor: not-allowed;
}
.gz-info__list-item--disabled:hover {
  background-color: unset;
}
.gz-info__list-item--disabled:active {
  background-color: unset;
  border: 2px solid #0000;
}
.gz-info__item-main {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
}
.gz-info__item-extra {
  display: flex;
  justify-content: space-between;
  opacity: 0.7;
  font-size: 0.75em;
}
</style>
